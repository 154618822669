<template>
  <div class="home">
    <top></top>
    <div class="center">
      <div class="nav-wrap">
        <!-- 头部 -->
        <div class="ordertop">
          <!-- <h2>上海宝钢集团旗舰店</h2> -->
          <!-- <div class="gimage">
            <img class="img" src="../../assets/index/hong.png" alt="" />
            尾易优选
          </div> -->
        </div>
        <!-- 公告  -->
        <div class="ordernotice">
          <div class="header">公告：交易之前请先签署电子合同，确保交易安全</div>
        </div>
        <!-- 订单详情 -->
        <div class="orderdetial">
          <div class="aaa">
            <div class="line"></div>
            <div class="detail">订单详情</div>
          </div>
          <!-- 描述 -->
          <div class="orderdescribe">
            <img  v-image-preview  :src="indentdetial.mainimage" alt="" />
            <div class="depict">
              <div class="top">
                <div class="left">
                  <h3>{{ indentdetial.name }}</h3>
                  ￥ <span>{{ indentdetial.price }}</span
                  >/吨
                </div>
                <!-- 操作状态 -->
                <!-- 待确认 -->
                <div class="right" v-if="indentdetial.state == 0">
                  <p>待确认</p>
                </div>
                <!-- 待支付 -->
                <div class="right" v-if="indentdetial.state == 1">
                  <p>已签署</p>
                  <!-- <p class="countdown">支付倒计时：18.59</p> -->
                </div>
                <!-- 交易中 -->
                <div class="right" v-if="indentdetial.state == 2">
                  <p>拒签署</p>
                </div>
                <!-- 已完成 -->
                <div class="right" v-if="indentdetial.state == 3">
                  <p>线上交易</p>
                </div>
                <!-- 已关闭 -->
                <div class="right" v-if="indentdetial.state == 4">
                  <p>线下交易</p>
                  <!-- <p class="countdown">关闭原因：退货退款</p> -->
                </div>
              </div>
              <div class="bottom">
                <div class="acount">
                  合计：<span>￥{{ indentdetial.goodstotalprice }}</span>
                </div>
                <div class="number">
                  数量：<span>{{ indentdetial.nubmer }} 吨</span>
                </div>
                <div class="actually">
                  实付金额：<span class="actuall"
                    >￥{{ indentdetial.paytotalprice }}</span
                  >
                  <el-button
                    plain
                    class="conversation"
                    @click="WannaTalk(indentdetial.sellerid)"
                    >发起会话</el-button
                  >
                  <el-button
                    type="danger"
                    class="pay"
                    @click="continuepay"
                    v-if="indentdetial.state == 1"
                    v-show="typebusell == 1"
                    >继续支付</el-button
                  >
                  <el-button
                    type="danger"
                    class="pay"
                    v-if="indentdetial.state == 0"
                    @click="surecontract(indentdetial.signurl)"
                    >确认合同</el-button
                  >
                </div>
                <Commoditymessage
                  @clears="clears"
                  :WannaTalkdialog="WannaTalkdialog"
                  :CompanyUserList="CompanyUserList"
                  @surees="surees"
                ></Commoditymessage>
              </div>
            </div>
          </div>
        </div>
        <!-- 导航流程 -->
        <div class="orderkey">
          <div class="kinds">
            <div
              v-for="(tabBar, index) in data"
              :key="index"
              class="a"
              :class="{ active: tabIndex == tabBar.id }"
              @click="toggleTab(tabBar.id)"
            >
              {{ tabBar.name }}
            </div>
          </div>
        </div>
        <el-dialog
          class="el-radiosect"
          :visible.sync="paydialogVisible"
          width="30%"
        >
          <div class="radiosect">
            <el-radio-group v-model="payId" @change="onRadioChange">
              <el-radio
                :value="item.code"
                :label="item.code"
                v-for="item in PayList"
                :key="item.code"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </div>
          <div class="radiosec">
            <el-button @click="paydialogVisible = false">取 消</el-button>
            <el-button type="danger" @click="paysure">确 定</el-button>
          </div>
        </el-dialog>
        <Iframe
          :iframeDialog="iframeDialog"
          ref="iframe"
          :resulturl="resulturl"
          @del="del"
        ></Iframe>
        <!-- 详细介绍 -->
        <div class="introduce">
          <div class="item" v-if="this.tabIndex == 0">
            <div class="left">
              <p><span> 订单编号 </span>{{ indentdetial.orderCode }}</p>
              <p>
                <span> 创建时间 </span>
                {{
                  $moment(indentdetial.createTime).format(
                    "YYYY年MM月DD日 HH时mm分ss秒"
                  )
                }}
              </p>
              <p v-show="indentdetial.state != 2">
                <span> 采购合同签署时间 </span>
                {{
                  indentdetial.contracttime
                    ? $moment(indentdetial.contracttime).format(
                        "YYYY年MM月DD日 HH时mm分ss秒"
                      )
                    : "无"
                }}
              </p>
              <p v-show="indentdetial.state != 2">
                <span> 采购合同 </span
                ><a @click="surecontract(indentdetial.signurl)">查看</a>
                <a @click="downloadcontract" v-show="indentdetial.state == 1"
                  >下载</a
                >
              </p>
            </div>
          </div>
          <div class="item" v-if="this.tabIndex == 1">
            <div class="left">
              <p><span>企业名称 </span>{{ buyercompanymessage.coname }}</p>
              <p><span>企业地址 </span>{{ buyercompanymessage.coaddress }}</p>
              <p><span>企业街道 </span>{{ buyercompanymessage.costreet }}</p>
              <p><span>联系方式 </span>{{ buyercompanymessage.cocontact }}</p>
            </div>
            <div class="right">
              <p>
                主营 <span>{{ buyercompanymessage.mainscope }}</span>
              </p>
            </div>
          </div>
          <div class="item" v-if="this.tabIndex == 2">
            <div class="left">
              <p><span>企业名称 </span>{{ sellercompanymessage.coname }}</p>
              <p><span>企业地址 </span>{{ sellercompanymessage.coaddress }}</p>
              <p><span>企业街道 </span>{{ sellercompanymessage.costreet }}</p>
              <p><span>联系方式 </span>{{ sellercompanymessage.cocontact }}</p>
            </div>
            <div class="right">
              <p>
                主营 <span>{{ sellercompanymessage.mainscope }}</span>
              </p>
            </div>
          </div>
          <div class="item" v-if="this.tabIndex == 3">
            <div class="left">
              <p>
                <span>分类 </span> {{ indentcommoditydetial.productline }}/{{
                  indentcommoditydetial.productname
                }}
              </p>
              <p><span>计价单价 </span> 吨</p>
              <p><span>是否预售 </span> 现货</p>
              <p>
                <span>质量等级 </span> {{ indentcommoditydetial.qualitylevel }}
              </p>
              <p><span>规格 </span> {{ indentcommoditydetial.productline }}</p>
              <p><span>仓库 </span> {{ indentcommoditydetial.storageplace }}</p>
              <p>
                <span>详情描述</span
                >{{
                  indentcommoditydetial.describe
                    ? indentcommoditydetial.describe
                    : "无描述"
                }}
              </p>
            </div>
            <div class="right">
              <p>
                涂层<span>{{ indentcommoditydetial.oil ? "有" : "无" }}</span>
              </p>
              <p>
                锌层<span>{{ indentcommoditydetial.zinclayer }}</span>
              </p>
              <p>
                是否有保证书<span
                  >{{ indentcommoditydetial.warranty ? "是" : "否" }}
                </span>
              </p>
              <p>
                加工工艺<span>{{
                  indentcommoditydetial.surfaceprocess
                    ? indentcommoditydetial.surfaceprocess
                    : "无"
                }}</span>
              </p>
              <p>
                有花无花<span>{{
                  indentcommoditydetial.pattern ? "有" : "无"
                }}</span>
              </p>
              <p>
                钢厂<span>{{ indentcommoditydetial.steelmill }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foo></Foo>
  </div>
</template>

<script>
import { Message } from "element-ui";
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
import tradingApi from "../../api/tradingApi";
import cityCode from "../../unitls/city_code";
import Commoditymessage from "../../components/chat/customer.vue";
import imApi from "../../api/imApi";
import Iframe from "../../components/center/iframe.vue";
export default {
  data() {
    return {
      WannaTalkdialog: false,
      // 银行类表
      PayList: [],

      payId: "",
      resulturl: "",
      paydialogVisible: false,
      options: [],
      resulturl: "",
      iframeDialog: false,
      sureid: this.$route.query.id,
      tabIndex: 0,
      ordercode: this.$route.query.ordercode,
      typebusell: this.$route.query.type,
      //  订单详情
      indentdetial: {},
      //订单商品详情
      indentcommoditydetial: {},
      //  买方企业信息
      buyercompanymessage: {},
      CompanyUserList: [],
      //  卖方企业信息
      sellercompanymessage: {},
      companystate: null,
      data: [
        { name: "订单信息", id: "0" },
        { name: "卖方企业信息", id: "1" },
        { name: "买方企业信息", id: "2" },
        { name: "产品详情", id: "3" },
      ],
      // 待确认
      confirme: false,
      // 待支付
      Continue: false,
      // 交易中
      transaction: false,
      //  已完成
      confirmed: false,
      // 已关闭
      occlude: false,
    };
  },
  created() {
    this.getOrderDetails();
  
  },
  mounted() {
    // 城市
    this.options = cityCode;
  },
  components: {
    // HelloWorld
    Top,
    Foo,
    Iframe,
    Commoditymessage,
  },
  methods: {
    // 下载合同
    downloadcontract() {
      this.$uweb.trackEvent("签约行为", "点击", "合同下载");
      tradingApi.downloadContract(this.ordercode, (res) => {
        var blob = res.data;
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        const filename = "订单合同.pdf";
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
    clears(bool) {
      this.WannaTalkdialog = bool;
    },
    surees(bool) {
      this.WannaTalkdialog = bool;
    },
    // 发起会话
    WannaTalk(companyId) {
      this.WannaTalkdialog = true;
      imApi.getCompanyUserList({ companyId: companyId }, (res) => {
        this.CompanyUserList = res.data.result;
       
      });
    },
    del(bool) {
      this.resulturl = "";
      this.addDialog = bool;
      this.iframeDialog = bool;
    },
    //  获取银行id
    onRadioChange(item) {
    
      this.payId = item;
    },
    // 继续支付
    continuepay() {
      this.paydialogVisible = true;
      // 获取银行列表
      tradingApi.getPayList("", (res) => {
        if (res.data.code == 200) {
          this.PayList = res.data.result;
        } else {
          Message.error(res.data.message);
        }
      });
    },
    // 确认继续支付
    paysure() {
      tradingApi.applyPayUrl(
        { orderCode: this.ordercode, payId: this.payId },
        (res) => {
          if (res.data.code == 200) {
            Message.success(res.data.message);
            this.paydialogVisible = false;
            this.iframeDialog = true;
            this.resulturl = res.data.result;
          } else {
            Message.error(res.data.message);
          }
        }
      );
    },
    // 确认合同
    surecontract(e) {
      this.iframeDialog = true;
      this.resulturl = e;
    },
    toggleTab(index) {
      this.tabIndex = index;
      if (this.tabIndex == 1) {
        //  获取买方企业信息
        tradingApi.getCompanyBriefInfo(
          { companyId: this.indentdetial.buyerid },
          (res) => {
          
            if (res.data.code == "200") {
              this.buyercompanymessage = res.data.result;
              this.buyercompanymessage.coaddress = this.pgetTextByValue(
                this.buyercompanymessage.coaddress
              );
            } else {
              Message.error(res.data.message);
            }
          }
        );
      } else if (this.tabIndex == 2) {
        //  获取卖方企业信息
        tradingApi.getCompanyBriefInfo(
          { companyId: this.indentdetial.sellerid },
          (res) => {
            if (res.data.code == "200") {
              this.sellercompanymessage = res.data.result;
              this.sellercompanymessage.coaddress = this.pgetTextByValue(
                this.sellercompanymessage.coaddress
              );
            } else {
              Message.error(res.data.message);
            }
          }
        );
      } else if (this.tabIndex == 3) {
        //  获取订单商品详情
        this.goodsDetails();
      }
    },
    // 获取订单详情
    getOrderDetails() {
      tradingApi.getOrderDetails({ ordercode: this.ordercode }, (res) => {
     
        if (res.data.code == 200) {
          this.indentdetial = res.data.result;
         
        } else {
          Message.error(res.data.message);
        }
      });
    },
    // 获取订单商品详情
    goodsDetails() {
      tradingApi.goodsDetails({ ordercode: this.ordercode }, (res) => {
        if (res.data.code == 200) {
          this.indentcommoditydetial = res.data.result;
         
        } else {
          Message.error(res.data.message);
        }
      });
    },
    pgetTextByValue(e) {
      var label = "";
      var s = this.options.filter(function (res) {
        return res.value == e[0];
      });
      if (s.length > 0) {
        label += s[0].label;
        var c = s[0].children.filter(function (res) {
          return res.value == e[1];
        });
        if (c.length > 0) {
          label += c[0].label;
          var q = c[0].children.filter(function (res) {
            return res.value == e[2];
          });
          label += q[0].label;
        }
      }
      return label;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-radio {
  // border: 1px solid red;
  padding: 10px;
}

.radiosec {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
}
::v-deep .el-radiosect .el-dialog {
  height: 400px !important;
}
::v-deep .el-dialog {
  height: 750px !important;
}
::v-deep .el-dialog__body {
  height: 650px;
}
iframe {
  width: 100%;
  height: 100%;
}
.home {
  min-width: 1100px;
}
.center {
  width: 100%;
  height: 880px;
  /* border: 1px solid red; */
}

.nav-wrap {
  // border: 1px solid red;
  // height: 100%;
  width: 60%;
  margin: auto;
  // 头部
  .ordertop {
    padding: 10px 0;
    //  border: 1px solid red;
    display: flex;
    justify-content: space-between;
    h2 {
      font-weight: normal;
      color: rgb(231, 80, 87);
    }
    .gimage {
      width: 80px;
      height: 32px;
      font-weight: 500;
      color: #e94f55;
      font-size: 14px;
      // margin-left: 30px;
      overflow: hidden;
      // border: 1px solid red;
      .img {
        position: relative;
        top: 3px;
      }
    }
  }
  //  公告
  .ordernotice {
    .header {
      width: 100%;
      background: #e94f55;
      height: 20px;
      color: white;
      // padding-left: 10px;
      padding: 2px 0;
      line-height: 20px;
      // box-sizing: border-box;
    }
  }
  // 订单详情
  .orderdetial {
    .aaa {
      display: flex;
      flex-direction: row;
      margin: 15px 0;
      .line {
        width: 2px;
        height: 18px;
        background: #e94f55;
        border-radius: 2px;
        margin-top: 4px;
      }
      .detail {
        width: 128px;
        height: 26px;
        font-size: 18px;
        font-weight: 600;
        color: #e94f55;
        margin-left: 20px;
      }
    }
    // 描述
    .orderdescribe {
      // border: 1px solid red;
      display: flex;

      img {
        width: 180px;
        height: 180px;
        border-radius: 5px;
      }
      .depict {
        flex: 1;
        //  border: 1px solid red;
        margin-left: 20px;
        .top {
          display: flex;
          justify-content: space-between;
          .left {
            h3,
            span {
              font-size: 18px;
              font-weight: 500;
              margin-bottom: 15px;
            }
            span {
              margin-bottom: 15px;
              display: inline-block;
            }
          }
          .right {
            color: rgb(231, 80, 87);
            font-size: 16px;
            // border: 1px solid red;
            text-align: center;
            // margin-left: -30px;
            flex: 0.4;
            .countdown {
              font-size: 12px;
            }
            p {
              margin-bottom: 10px;
            }
          }
        }
        .bottom {
          .acount,
          .number {
            margin-top: 10px;
            font-size: 14px;
            span {
              font-size: 12px;
              color: rgb(136, 134, 134);
              margin-left: 25px;
            }
          }
          .actually {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-top: 10px;
            .actuall {
              font-size: 16px;
            }
            .el-button {
              height: 35px;
              line-height: 5px;
              width: 140px;
              margin-left: 50px;
            }
            .conversation {
              color: rgb(231, 80, 87);
              border: rgb(231, 80, 87) 1px solid;
            }
          }
        }
      }
    }
  }
  // 导航流程
  .orderkey {
    background-color: rgb(253, 253, 253);
    margin-top: 30px;
    .kinds {
      cursor: pointer;
      width: 80%;
      height: 30px;
      font-size: 15px;
      line-height: 30px;
      color: rgb(136, 134, 134);
      //  border: 1px solid red;
      margin: 20px 0;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      .active {
        color: #e94f55;
      }
    }
  }
  // 详细介绍
  .introduce {
    background-color: rgb(253, 253, 253);
    margin-bottom: 50px;
    // height: 500px;
    .item {
      // border: 1px solid red;

      color: rgb(105, 105, 105);
      // border-bottom: 1px solid #ccc;
      padding: 25px 0;
      display: flex;
      justify-content: space-between;
      .left {
        flex: 1;
        // border: 1px solid red;
        p {
          margin: 10px 0;
          font-size: 16px;
          width: 100%;
          a {
            cursor: pointer;
            margin-left: 20px;
            color: rgb(29, 29, 29);
            border-bottom: 1px solid rgb(29, 29, 29);
          }
          span {
            // border: 1px solid red;
            margin-left: 20px;
            // border: 1px solid red;
            // width: 130px;
            height: 20px;
            color: rgb(51, 51, 51);
            color: 14px;
            display: inline-block;
            margin-right: 60px;
          }
        }
      }
      .right {
        flex: 0.6;
        p {
          margin: 10px 0;
          font-size: 16px;
          width: 300px;
          // border: 1px solid red;
          span {
            width: 100px;
            // border: 1px solid red;
            height: 20px;
            color: rgb(29, 29, 29);
            color: 14px;
            display: inline-block;
            margin-left: 50px;
          }
        }
      }
    }
  }
}
</style>
